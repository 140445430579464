@mixin orangeBtn($bgColor:transparent) {
	background:$bgColor url('/images/buttons/orange-button.png')no-repeat center center;
	background-color:transparent !important;
	background-size:100% 100%;
	border:none;
	color:$orange !important;
	min-height:45px;
	max-height:45px;
	line-height:45px;
	padding-top:0 !important;
	padding-bottom:0 !important;
	font-family:$serenity;
	border-radius:0 !important;
	letter-spacing:0.6px;
	font-size:14px;
	transform:none;

	&:hover {
		color:#FFFFFF !important;
		background: linear-gradient(153.92deg, #A4603E 0%, #EB9A62 100%);
	}
}

@mixin orangeUnderlineBtn(){
	display:inline-block;
	line-height:45px;
	font-weight:800 !important;
	background:transparent url(../../../images/buttons/gradient-underline.png) no-repeat center calc(50% + 14px) !important;
	background-size:100% 3px !important;
	color:$orange !important;
	padding:0 !important;
	border:none;
	font-size:14px;
	transform:none;
	font-family:$serenity;

	&.btn:hover {
		color:$orange !important;
		background-color:transparent !important;
	}

	@media screen and (max-width:500px) {
		font-size:12px;
	}
}

@mixin whiteUnderlineBtn(){
	display:inline-block;
	line-height:45px;
	color:#FFFFFF !important;
	padding:0 !important;
	border:none;
	position:relative;
	transform:none;
	font-family:$serenity;

	&:after {
		content:'';
		display:block;
		width:100%;
		height:3px;
		position:absolute;
		bottom:8px;
		background-color:#FFFFFF;
	}

	&.btn:hover {
		color:#FFFFFF !important;
		background-color:transparent !important;
	}
}

@mixin generalHeader($color:$blue) {
	color:$color;
	font-family:$bodoni;
	font-size:60px;
	letter-spacing:0.5px;
	line-height:72px;
	font-weight:400;

	@media screen and (max-width:767px) {
		font-size:32px;
		line-height:38px;
		letter-spacing:0.25px;
	}
}

@mixin smallerHeader($color:$blue){
	color:$color;
	font-size:26px;
	line-height:28px;
	letter-spacing:4.3px;
	text-transform:uppercase;
	font-family:$serenity;
}

@mixin parallaxSubheader($color:#FFFFFF) {
	color:$color !important;
	font-family:$serenity;
	letter-spacing:4.3px !important;
	font-size:26px !important;
	font-weight:800;
	margin-top:0;
	line-height:28px;

	@media screen and (max-width:767px) {
		font-size:18px !important;
		line-height:23px;
		letter-spacing:3px !important;
	}
}

@mixin imgOverlayCaption(){
	font-size: 30px !important;
	line-height:40px;
	font-family:$bodoni;
}

@mixin reverseTriangleMask(){
	position:absolute;
	content:'';
	display:block;
	width:100%;
	height: $triangleHeight;
	background: linear-gradient(to right top, transparent 49.9%, white 50%);
	top:0;
	left:0;

	@media screen and (max-width:767px) {
		height:$mobileTriangleHeight;
	}
}

@mixin alternateHeader() {
	.logo-container .logo-image {
		&.logo-light {
			display:none;
		}
		&.logo-dark {
			display:block !important;
		}
	}

	.menu-wrapper #masthead {
		&.is_stuck {
			.row-menu {
				border-bottom:none;
			}
		}

		.row-menu {
			border-bottom:1px solid #575757;

			#menu-main-header-menu >li a {
				color: #4A4A4A;

				&:hover:after {
					background-color:#bbbbbb;
					background-size:cover;
				}
			}

			#menu-main-header-menu > li.current-menu-item {
				a {
					@include navItemUnderline();
					
					&:after {
						background-color:#bbbbbb;
					}
				}

				&:first-child {
					a:after {
						left:0;
						width:calc(100% - 20px);

						@media screen and (max-width: 1159px) {
							width:calc(100% - 12px);
						}
					}
				}
			}
		}
	}
}

@mixin carouselStyles() {
	.owl-carousel-wrapper {
		.owl-prev,
		.owl-next {
			.owl-nav-container {
				background-color:transparent !important;

				i {
					color:#000;
					font-size:30px;
				}
			}
		}

		.owl-prev {
			margin-left:0 !important;
		}

		.owl-next {
			margin-right:0 !important;
		}

		@media screen and (max-width:767px) {
		// CSS overwrites to break carousel default styling and stack images at mobile

			.owl-carousel-container {
				max-width:100% !important;

				.owl-stage {
					max-width:100% !important;
					width:100% !important;
					transform:none !important;
					transition:none !important;
					display:block !important;
					height:auto !important;

					.owl-item {

						width:100% !important;
						display:block !important;
						height:auto !important;

						&.cloned {
							display:none !important;
						}

						> .tmb {
							width:auto;
							padding-right:0;
						}
					}
				}
			}

			.owl-prev,
			.owl-next {
				display:none !important;
			}
		}

	}
}

@mixin banerlessPage(){
	.post-wrapper .post-body {
		padding-top:180px;

		@media screen and (max-width:959px) {
			padding-top:80px;
		}
	}
}

@mixin navItemUnderline() {
	&:after {
		position:absolute;
		top:calc(50% + 20px);
		left:20px;
		width:calc(100% - 40px);
		height:3px;
		background-color:#FFF;
		display:inline-block;

		@media screen and (max-width: 1159px) {
			width:calc(100% - 24px);
			left:12px;
		}

	}
}