footer.site-footer {
	background-color:#444;
	box-sizing:border-box;

	color:rgba(255, 255, 255, 0.5);

	.text-left, 
	.text-right {
		padding:0 25px;
		height:60px;
	}


	.text-left {
		font-size:15px;
		font-family:$serenity;
		font-weight:500;
		letter-spacing:0.5px;
		
		p, li, a {
			font-size:15px;
		}
	}

	.menu-footer-terms-container {
		display:inline-block;
		margin-left: 10px;
		margin-top:0;

		#menu-footer-terms {
			margin-top:0;

			li {
				a {
					color:rgba(255, 255, 255, 0.5);
					text-decoration:underline;
				}
			}
		}
	}

	.footer-right-icons {
		.monument-logo {
			width: 120px;
		}

		.mb-logo {
			width:77px;
			margin-left: 25px;

			@media screen and (max-width:767px) {
				margin-left:0;
			}
		}

		.eho-logo {
			width: 18px;
			height:19px;
			margin-left: 25px;

			@media screen and (max-width:767px) {
				margin-left:0;
				margin-top:13px;
			}
		}
	}

	@media screen and (max-width:959px) {
		.row-container {
			padding-top:0;
			padding-bottom:0;

			.row-parent {
				display:flex;
				flex-direction:row;

				.col-lg-6 {
					width:50%;
					max-width:50%;
					line-height:60px;
					min-height:60px;

					&.text-left {
						text-align:left;
					}
					&.text-right {
						text-align:right;

						.footer-right-icons {
							padding-top:8px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width:767px) {
		.row-container {
			.row-parent {
				display:block;

				.col-lg-6 {
					width:100%;
					max-width:100%;
					line-height:22px !important;
					min-height:0;

					&.text-right {
						text-align:left;
						
						.footer-right-icons {
							display:flex;
							justify-content: space-between;
						}
					}


				}


			}
		}
	}

	@media screen and (max-width:500px) {
		.row-container {
			.row-parent {
				.col-lg-6 {
					.menu-footer-terms-container {
						margin-left:0;
						display:block;
					}
				}
			}
		}
	}
}