// Fonts
$bodoni: "ltc-bodoni-175",serif !important;
$serenity: "serenity",sans-serif !important;

// Colors
$blue: #1A3A58;
$orange: #D38459;

//Triangle Mask Heights
$triangleHeight: 15.28vw;
$mobileTriangleHeight: 50px;
