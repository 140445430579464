.page-template-page-interiors {
 
	@include alternateHeader();


	.parallax-hero {
		padding-bottom:260px;
		padding-top:160px;

		&:after {
			display:none !important;
		}

		.row-parent .row-inner .column_parent .heading-text{

			h1 {
				color:#979797 !important;

				span {
					color:$blue !important;
				}
			}

			&.parallax-subheader {
				h2 span {
					@include parallaxSubheader(#979797);
				}
			}
		}

		.uncode_text_column {
			max-width:75%;
			margin:0 auto;
		}

		.buttons-row {
			margin-top:10px !important;	
		}
	}

	.pre-footer-row  {
		margin-top:160px;
	}

	@media screen and (max-width:959px) {
		.parallax-hero {
			padding-top: 140px;
			padding-bottom:140px;
		}
	}

	@media screen and (max-width:767px) {
		.parallax-hero {
			padding-top: 80px;
			padding-bottom: 60px;
		}
	}
}