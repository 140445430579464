.flip-flop-grid {
	background:#C8CFDB url(../../../images/backgrounds/flip-flop-grid-bg.jpg)no-repeat center center;
	background-size:cover;
	padding-top:140px;
	padding-bottom:140px;

	.uncell {
		.uncont {
			.heading-text {
				h2 {
					@include generalHeader(#000000);
				}
			}

			.uncode_text_column,
			.btn-container {
				margin-top:0 !important;
			}

			.uncode_text_column {
				@media screen and (min-width:1200px) {
					max-width:75%;
				}
			}

			.uncode-single-media{
				+ .uncode-single-media {
					margin-top:20px;

					@media screen and (max-width:959px) {
						margin-top:0;
					}
				}
			}
		}
	}

	.flip-flop-left {
		.first-left-img {
			margin-top:0 !important;
		}
	}

	.flip-flop-right {
		.flip-flop-text-wrap {
			padding-top:20px;
			padding-left:40px;
		}
	}

	@media screen and (max-width:1249px) {

	}

	@media screen and (max-width:959px) {

		padding-top:40px;
		padding-bottom:40px;

			.flip-flop-left,
			.flip-flop-right {
				.uncont {
					display:flex;
					flex-direction:row;
					flex-wrap:wrap;
					justify-content:space-between;

					.flip-flop-text-wrap {
						min-width:100%;
					}
				}
			}

			.flip-flop-left {
				.top-left-text {
					min-height: 0 !important;
					margin-bottom:30px;
				}
			}

			.flip-flop-right {
				.flip-flop-text-wrap {
					padding-top:20px;
					padding-left:0;
				}
			}

			.uncode-single-media {
				max-width:calc(50% - 8px);
				min-width:calc(50% - 8px);

				.dummy {
					padding-top:100% !important;
				}

				img {
					height:100%;
					width:auto;
					min-width:100%;
				}
			}
	}

	@media screen and (max-width:767px) {
		padding-top: 0;
		padding-bottom:0;

		.flip-flop-right {
			.flip-flop-text-wrap {
				padding-top:0;
				padding-left:0;
			}
		}

		.btn-container a {
			transform-origin:left !important;
		}
	}
}