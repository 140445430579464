.interior-design-option {
	margin-bottom:100px;

	&.organic-modernist,
	&.rooftop {
		margin-top:-152px;
	}
	
	> .row-parent {
		padding-top:0 !important;
		padding-bottom:0 !important;
	}

	.interior-type-intro-text {
		background-image: url('../../../images/backgrounds/flip-flop-grid-bg.jpg');
		background-repeat:no-repeat;
		background-position: calc(50% + 36px) center;
		background-size:cover;
		padding-top:120px !important;
		padding-bottom:120px !important;

		.heading-text {
			text-align:center;
			h2 {
				
				span {
					@include generalHeader();
				}
			}
		}

		.uncode_text_column {
			text-align:center;
			max-width:55%;
			margin:0 auto;
		}

		.btn-container {
			text-align:center;
		}
	}

	.images-and-carousel-row {
		@include carouselStyles();
		margin-top:100px !important;

		
		.images-column {

			.uncont {
				display:flex;
				flex-direction:column;
				height:100%;

				.uncode-single-media{
					+ .uncode-single-media {
						margin-top: 68px;
					}
				}

				> div:last-of-type {
					&.uncode-single-media {
						.single-wrapper {
							//position:absolute;
							bottom:0;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width:959px) {
		margin-bottom:0;

		&.organic-modernist,
		&.rooftop {
			margin-top:-100px;
		}

		.interior-type-intro-text {
			background-position:center center !important;
			padding-top:90px !important;
			padding-bottom:90px !important;
			margin-bottom:40px;

			.uncode_text_column {
				max-width:80%;
			}
		}

		.images-and-carousel-row { 
			margin-top:0 !important;
			margin-bottom:40px;

			.row-inner {
				display:flex;
				flex-direction:column;

				.col-lg-1 {
					display:none !important;
				}

				.images-column {
					order:2;
					padding-top:0 !important;
				}

				.col-lg-7 {
					order:1;
					margin-bottom:40px;
					padding-top:0;
				}
			}
		}
	}


	@media screen and (max-width:767px) {
		margin-bottom:25px;

			&.organic-modernist,
			&.rooftop {
				margin-top:-48px;
			}

			.interior-type-intro-text {
				padding-top:60px !important;
				padding-bottom:60px !important;
				margin-bottom:0 !important;	

				.uncode_text_column {
					max-width:100%;
					padding:0 20px;
				}			
			}

			.images-and-carousel-row {
				margin-top:0 !important;
				margin-bottom:0 !important;

				.row {
					.row-inner {
						.images-column {
							.uncode-single-media {
								margin-top:25px !important;
							}
						}
					}
				}

				.col-lg-1 {
					display:none !important;
				}

				.col-lg-7,
				.images-column {
					padding-top:0 !important;
					margin-top:0 !important;
				}

				.col-lg-7 {
					margin-bottom:0 !important;
				}

				.owl-carousel-wrapper {
					.owl-carousel-container {
						.owl-stage {
							.owl-item {
								margin-top:25px;
							}
						}
					}

				}	
			}
	}
}