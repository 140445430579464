::selection {
	background:#444;
}

.tmb-content-overlay {
	p.t-entry-meta {

		max-width:80%;
		margin-left:auto !important;
		margin-right:auto !important;

		span {
			@include imgOverlayCaption();
		}

	}
}

.t-overlay-content {
	.t-overlay-text {
		.t-entry {
			.t-entry-meta {
				max-width:80%;
				margin-left:auto !important;
				margin-right:auto !important;
				transform:none !important;

				span {
					@include imgOverlayCaption();
				}
			}
		}
	}
}

body p {
	user-select: auto !important;
	
}

@media screen and (max-width:767px) {
	.main-container .row-container .row-parent {
		padding-left:25px;
		padding-right:25px;
		padding-top:25px;
		padding-bottom:25px;
	}
}