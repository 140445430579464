.page-template-page-neighborhood {

	@include alternateHeader();

	// Overwrite default header text styling
	.parallax-hero .row-parent .row-inner .column_parent .heading-text h1 {
		color: #979797 !important;

		span {
			color: $blue;
		}
	}

	.carousel-image-text-row {
		@include carouselStyles();

		.uncode_text_column {
			text-align:center;
			font-family:$serenity;
			font-weight:300;

			h1, h2, h3, h4, h5, h6 {
				@include smallerHeader();
			}
		}

		&.bottom-align-columns {
			.col-lg-7 {
				.uncont {
					.owl-item {
						.t-entry-visual {
							position:static;
							border:none;
							img {
								position:absolute;
								bottom:0;
							}
						}
					}
				}
			}
		}

	}

	.map-row {
		.row-parent {
			padding-left:0;
			padding-right:0;

			
		}
	}

	@media screen and (max-width:959px) {
		.carousel-image-text-row {
			.row-inner {
				display:flex;
				flex-direction:column;

				.col-lg-7 {
					order:2;
					padding-top:36px !important;
				}

				.col-lg-5 {
					order:1;
					padding-top:0;

					.uncont {
					display:flex;
					flex-direction:column;

						.uncode-single-media  {
							order:2;
							margin-top:36px !important;
						}

						.uncode_text_column {
							order:1;
							margin-top:0 !important;
						}
					}

				}
			}
		}
	}

	@media screen and (max-width:959px) {

		.pre-footer-row.row-container.vc_row {
			margin-top:74px;
		}
	}

	@media screen and (max-width:767px) {

		.parallax-hero + .vc_row .row,
		.map-row + .vc_row .row {
			padding-top:0 !important;
		}

		.carousel-image-text-row {
			margin-top:0 !important;
			.row-inner {
				.col-lg-5,
				.col-lg-7 {
					padding-top:25px !important;
				}

				.owl-item:not(.cloned) {
					+ .owl-item {
						margin-top:25px;
					}
				}

				.uncode_text_column {
					margin-top:25px !important;
				}

			}
		}
	}
}