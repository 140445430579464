.page-template-page-floorplans {

	@include alternateHeader();

	// Overwrite default header text styling
	.parallax-hero .row-parent .row-inner .column_parent .heading-text h1 {
		color: #979797 !important;

		span {
			color: $blue;
		}
	}
}