.parallax-hero {
	text-align:center;
	position:relative;
	padding-bottom:214px;

	&:after {	
		position:absolute;
		content:'';
		display:block;
		width:100%;
		height: $triangleHeight;
		background: linear-gradient(to left bottom, transparent 49.5%, white 50%);
		bottom:-1px;
		left:0;

		@media screen and (max-width:767px) {
			height:$mobileTriangleHeight;
		}
	}

	.row-parent {

		.row-inner {
			min-height:100%;
			text-align:center;

			.column_parent {
				vertical-align:middle;

				.heading-text,
				.btn-container {
					text-align:center;
				}

				.heading-text,
				.heading-text h1 {
					font-size:36px;
					color:#FFF !important;
					font-family:$serenity;
					text-transform:uppercase;
					letter-spacing:6px;

					span {
						font-family:$bodoni;
						font-size: 120px;
						line-height:144px;
						letter-spacing: 3.4px;
						display:block;
						font-weight:400;
					}
				}

				.orange-button {
					background: linear-gradient(153.92deg, #A4603E 0%, #EB9A62 100%);
					color:#FFF !important;
				}
			}
		}

	}

	.buttons-row,
	.text-columns {
		margin-top:10px !important;

		.row-inner {
			display:inline-block;
			width:auto;

			.column_child {
				width:auto;
				display:inline-block;
				padding:0 20px;

				.uncoltable {
					width:auto;

					.uncode_text_column {

						p, li, a {
							color:#FFF;
							font-weight:600;
							font-family:$serenity;
							font-size:16px;
							line-height:22px;

							br {
								line-height:0;
							}
						}

						a {
							display:inline-block;
							text-decoration:none;
						}
						
					}
				}

				@media screen and (max-width:767px) {
					display:block;
					text-align:center;

					.uncoltable {
						margin:0 auto;
					}
				}

			}
		}
	}

	.text-columns {
		.column_child {
			padding:0 !important;

			.uncol {
				border-right:1px solid #FFF;
				padding:0 20px !important;
			}

			&.last-column {
				.uncol {
					border-right:none;
				}
			}
		}

		@media screen and (max-width:767px) {
			.row-inner {
				min-width:0;

				.column_child .uncol {
					border-right:none;
					padding:0 !important;

					.uncoltable {
						margin:0;
					}
				}
			}
		}
	}

	&.smaller-hero {
		overflow:hidden;
		padding-bottom:260px;
		padding-top:160px;

		&:after {
			background:#FFF url('../../../images/orange-gradient-bar.png')no-repeat center top;
			background-size: 100% 35px;
			width:150vw;
			transform: skewY(187deg);
			top:calc(100% - 80px);
			bottom:auto;
			height:400px;
		}
	}

	@media screen and (max-width:959px) {
		.row-parent {
			.row-inner {
				.column_parent {
					.heading-text,
					.heading-text h1 {
						font-size:30px;
						letter-spacing:5px;

						span {
							font-size:80px;
							line-height:100px;
							letter-spacing:2.5px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width:767px) {
		// Homepage specific
		&.home-hero .row-parent .row-inner .column_parent .heading-text, 
		&.home-hero .row-parent .row-inner .column_parent .heading-text h1 {
			font-size:30px;
			letter-spacing:5px;
			line-height:32px;

			span {
				font-size:72px;
				line-height:86px;
				letter-spacing:2px;
			}
		}
		// End homepage specific

		.row-parent {
			.row-inner {
				.column_parent {
					.heading-text,
					.heading-text h1 {
						font-size:24px;
						letter-spacing:4px;
						line-height:26px;

						span {
							font-size:50px;
							line-height:60px;
							letter-spacing:1.42px;
						}
					}

					.parallax-subheader {
						margin-top:20px !important;
					}

					.buttons-row {
						margin-top:0 !important;

						.column_child {

							.btn-container {
								margin-top:12px;
								display:inline-block;
								width:auto;
								text-align:center;

								a.btn {	
									transform-origin: center !important;
									line-height:36px;
								}
							}
						}
					}

					.uncode_text_column {
						margin-top:0;
						max-width:100%;
					}
				}
			}
		}
	}

	@media screen and (max-width:415px) {
		.row-parent {
			.row-inner {
				.column_parent {
					.heading-text,
					.heading-text h1 {

						span {
							font-size:38px;
							letter-spacing:1px;
						}
					}
				}
			}
		}
	}
	
}