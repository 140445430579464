.btn-container {
	.orange-button {
		@include orangeBtn();
	}

	.orange-underline-btn {
		@include orangeUnderlineBtn();
	}

	.white-underline-btn {
		@include whiteUnderlineBtn();
	}

	@media screen and (max-width:767px) {
		a {
			transform-origin:center !important;
		}
	}
}

.intro-text-row {
	.uncode_text_column {
		max-width: 62%;
		margin-left:auto;
		margin-right:auto;

		p, li, a {
			font-size: 18px;
			color: #333333;
			text-align:center;

			@media screen and (max-width:767px) {
				font-size:14px;
			}
		}

		@media screen and (max-width:959px) {
			max-width:70%;
		}

		@media screen and (max-width:767px) {
			max-width:100%;
		}
	}

	@media screen and (max-width:959px) {
		.row-inner {
			.column_parent,
			.column_child {
				padding-top:0 !important;
			}
		}	
	}
}

