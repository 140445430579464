/*
Theme Name: Avidian
Description: A custom theme build for the Avidian Condos based off of a Child theme for Uncode theme
Template: uncode
Version: 1.0.0
*/

@import 'global/variables';
@import 'global/mixins';
@import 'global/general';
@import 'global/utilities';
@import 'global/typography';
@import 'global/overwrites';

@import 'components/header';
@import 'components/top-promo-bar';
@import 'components/parallax-hero';
@import 'components/after-parallax-content';
@import 'components/orange-divider';
@import 'components/flip-flop-grid';
@import 'components/interior-design';
@import 'components/floorplan-row';
@import 'components/gallery-section';
@import 'components/map-plugin';
@import 'components/instagram-feed';
@import 'components/prefooter';
@import 'components/footer';


@import 'pages/homepage';
@import 'pages/interiors';
@import 'pages/amenities';
@import 'pages/neighborhood';
@import 'pages/floorplans';
@import 'pages/contact';
@import 'pages/dark-header';
@import 'pages/errorDoc';

