.floorplan-row {
	@include carouselStyles();

	.owl-item .tmb-carousel .dummy {
		padding-top:100% !important;
		box-shadow:inset 0 0 900px rgba(0, 0, 0, 0.05); 
	}

	.owl-item .tmb-carousel img {
			position:absolute;
			top:50%;
			left:50%;
			max-width:calc(100% - 50px);
			transform:translate(-50%, -50%);
		}

	.text-col {
		text-align:center;

		.uncode-single-media-wrapper {
			img {
				max-height: 72px;
				width:auto;
				margin:0 auto;
			}
		}

		.uncode_text_column {
			text-align:center;

			h1,h2,h3,h4,h5,h6 {
				@include smallerHeader();
			}

			p {
				max-width:64%;
				margin-left:auto;
				margin-right:auto;
			}
		}

		.buttons-row {
			text-align:center;

			.row-inner {
				display:inline-block;
				width:auto;

				.column_child {
					width:auto;
					display:inline-block;

					.uncoltable {
						width:auto;
					}
				}
			}
		}

		.btn-container {
			text-align:center;
		}
	}

	@media screen and (max-width:959px) {
		.row-parent {
			padding-bottom:0 !important;

			.row-inner {
				display:flex;
				flex-direction:column;
				
				.col-lg-7 {
					order:2;
					padding-top:0 !important;
				}

				.text-col {
					order: 1;
					margin-bottom:40px;
					padding-top:0 !important;

					.buttons-row {
						.row-inner {
							.column_child {
								padding-top:0;
								margin:0 5px;
							}
						}

						+ .btn-container {
							margin-top:10px;
						}
					}
				}
			}
		}
	}

	@media screen and (max-width:767px) {
		.row-parent {
			.row-inner {
				.col-lg-7 {
					.owl-item {
						+ .owl-item {
							margin-top:25px;
						}
					}
				}

				.text-col {
					margin-bottom:0;
					padding-bottom:0 !important;
				}
			}
		}
	}
}