body {

	.uncode_text_column {
		h2 {
			font-size:30px;
		}

		h3 {
			font-size:26px;
		}

		a {}
	

	p, li {
		font-family:$serenity;
		font-weight:300;
		font-size:18px;

		@media screen and (max-width:767px) {
			font-size: 14px;
			line-height:20px;
		}

		a {
			color:#4a4a4a;
			text-decoration:underline;

			&:hover {
				text-decoration:underline;
			}
		}
		}
	}
}