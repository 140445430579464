.page-template-page-contact {

	.contact-page-form-wrap {

		.text-columns {

			+ .intro-text-row {
				margin-top:60px !important;

				@media screen and (max-width:767px) {
					margin-top:20px !important;
				}
			}

			.row-inner {
				display:block;
				width:100%;
				text-align:center;

				.column_child {
					width:auto;
					display:inline-block;
					padding:0 !important;

					&.last-column {
						.uncol {
							border-right:none !important;
						}
					}

					.uncol {
						border-right:1px solid #4a4a4a;
						padding:0 20px !important;
					}

					.uncoltable {
						width:auto;

					.uncode_text_column {

						p, li, a {
							font-weight:600;
							font-family:$serenity;
							font-size:16px;
							line-height:22px;

							br {
								line-height:0;
							}
						}

						a {
							display:inline-block;
							text-decoration:none;
						}
						
					}
				}

			}
		}

			@media screen and (max-width:767px) {
				text-align:center;

				.row-child {
					display:inline-block;
					width:auto;
				}
				
				.row-inner {
					min-width:0;

					.column_child {
						display:block;
						width:100%;

						.uncol {
							border-right:none;
							padding:0 !important;

							.uncoltable {
								margin:0 auto;

								.uncode_text_column {
									text-align:center !important;
								}
								
							}
						}
					}
				}
			}
		}

		.row-parent {
			padding-bottom:0;

			iframe {
				@media screen and (max-width:828px) {
					min-height:940px;
				}
			}

			.after-contact-form-content {
				margin-top:10px !important;

				a {
					text-decoration:none !important;
				}
			}
		}
	}

	.pre-footer-row {
		padding-top:190px;
		margin-top:40px !important;

		@media screen and (max-width:959px) {
			padding-top:55px;
		}

		@media screen and (max-width:767px) {
			padding-top:20px;
		}
	}
}