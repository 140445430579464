.instagram-row {
	.instagram-row-intro {
		.uncode_text_column {
			h3 {
				color:$blue;
				font-size:26px;
				font-weight:800;
				text-transform:uppercase;
				letter-spacing:2.6px;
				font-family: $serenity;

				img { 
					max-width: 52px;
					vertical-align:middle;
					margin-right:18px;
				}
			}
		}
	}
}