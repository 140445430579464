.pre-footer-row.row-container.vc_row {
	background:url('../../../images/backgrounds/orange-textured-bg.jpg')no-repeat center center;
	background-size:cover;
	position:relative;
	margin-top:150px;

	&.without-cta {
		padding-top:190px;
		margin-top:40px !important;

		@media screen and (max-width:959px) {
			padding-top:55px;
		}

		@media screen and (max-width:767px) {
			padding-top:20px;
		}
	}

	// PreFooter CTA Styles
	&.has-large-cta {

		.prefooter-cta.prefooter-cta-large.row-internal.row-container {
			margin-top:-220px !important;
			height:740px;
			min-height:740px;
			max-height:740px;

			@media screen and (max-width:959px) {
				height:350px;
				min-height:350px;
				max-height:none;
				margin-top:-100px !important;
			}

			@media screen and (max-width:767px) {
				height:185px;
				min-height:185px;
			}

		}

		@media screen and (max-width:767px) {
			margin-top:110px;
		}

	}

	&:before {
		@include reverseTriangleMask();
	}

	.uncont {

		.prefooter-cta {

			height:350px;
			min-height:350px;
			padding-top:40px;
			padding-bottom:40px;

			@media screen and (max-width:767px) {
				height:auto;
				min-height:0;
				max-height:none;
				padding-top:40px;
				padding-bottom:40px;
			}

			&.row-internal.row-container {
				margin-top:-100px !important;
			}

			.row-child,
			.row-child .row-inner,
			.row-inner {
				height:100% !important;
				min-height:100% !important;
			}

			.row-child .row-inner {
				@media screen and (max-width:959px) {
					display:flex !important;
				}
			}

			.heading-text {
				max-width:80%;
				margin-left:auto;
				margin-right:auto;

				h2 {
					 span {
					 	@include generalHeader($color:#FFFFFF);

					 	@media screen and (max-width:959px) {
					 		font-size: 52px;
					 		line-height: 64px;
					 	}

					 	@media screen and (max-width:767px) {
					 		font-size: 24px;
					 		line-height: 29px;
					 		letter-spacing: 0.2px;
					 	}
					 }
				}
			}

			.orange-button {
				background-color:rgba(0, 0, 0, 0.45) !important;

				&:hover {
					background-color:transparent !important;
				}
			}

			&.prefooter-cta-large {
				
			}
		}
		// End Prefooter CTA Styles

		// Prefooter Columns Styles
		.cb-prefooter-columns {
			margin-top:92px !important;

			@media screen and (max-width:959px) {
				margin-top:40px !important;

				.row-inner {
					display:flex;
					flex-direction:row;
					flex-wrap:wrap;
				}
			}

			p, li, a {
				font-size: 16px;
				color: #000000;
				font-weight:500;
				font-family:$serenity;
				line-height:22px;
				text-decoration:none;
			}

			a {
				display:inline-block;
			}

			h2.widgettitle {
				font-size: 14px;
				color: #000000;
				font-weight:600;
				font-family:$serenity;
				text-transform:uppercase;
				line-height:22px;
				leter-spacing:1.21px;
			}


			ul.menu {
				padding-left:0 !important;
				list-style-type:none;

				li {
					line-height: 28px;
					text-transform:uppercase;
					letter-spacing:1.21px;

					a {
						font-size:14px;
						font-weight:600;
						line-height:28px;
					}
				}
			}

			.first-column {
				.uncode-single-media-wrapper {
					img {
						width: 88px;
						max-width: 88px;

						@media screen and (max-width:767px) {
							width: 64px;
							max-width: 64px;
						}
					}
				}
			}

			.third-column,
			.fourth-column {
				@media screen and (max-width:959px) {
					max-width:50% !important;
				}
				
			}

			.fifth-column {

				#menu-social-media {
					padding-left:0;
					margin-top:20px;

					@media screen and (max-width:959px) {
						display:inline-block;
						width:auto;

						li {
							a {
								padding:0;
							}
						}
					}
					
					li {
						position:relative;
						display:inline-block !important;

						&:before {
							font-family:FontAwesome;
							position:absolute;
							left:0;
							color:#FFF;
							font-size:18px;

						}

						&.fa-instagram {
							margin-left:2px;
						}
						
						a {
							width: 22px;
							height: 22px;
							position:relative;
							display:block;
							text-indent: 100%;
							white-space: nowrap;
							overflow: hidden;
							box-sizing:border-box;
							color:transparent;

						}
					}
				}

			}
		}
	}

	@media screen and (max-width:767px) {
		margin-top:90px;
		
		.btn-container {
			margin-top:10px !important;
		}
	}
}