body.home {
	.parallax-hero {
		min-height: calc(100vh + 214px);
    	height: calc(100vh + 214px);

		.row-parent:not(.row-slider):not(.row-header) {
			height:100% !important;

			.row-inner {
				height:100% !important;
				padding-top:100px;

				.orange-button {
					background: linear-gradient(153.92deg, #A4603E 0%, #EB9A62 100%);
					background-color:none !important;
					color:#FFF !important;
				}

				.uncont {
					
				}
			}
		}

		@media screen and (max-width:767px) {
			&:not(.home-hero) {
				height:auto;
				min-height:0;
				padding:100px 0;
			}
		}
	}
}