.top-promo-bar {
	position:relative;
	background:$orange url('../../../images/orange-gradient-bar.png')no-repeat center center;
	background-size:cover;
	box-sizing:border-box;
	text-align:center;
	font-family:$serenity;
	font-weight:600;
	text-transform:uppercase;
	font-size:18px;
	height:60px;
	max-height:60px;
	margin-top:-60px;
	transition:margin-top 1s ease-in-out;

	&.show-promo {
		margin-top:0;
	}

	.close-promo {
		width: 30px;
		height:30px;
		background:transparent url('../../../images/buttons/close-x.png')no-repeat center center;
		background-size:12px;
		position:absolute;
		top:50%;
		transform:translateY(-50%);
		right:11px;
		cursor:pointer;

	}
	.promo-bar-text-wrap {
		width:calc(100% - 100px);
		margin:0 auto;

		p, a {
			margin-top:0;
			color:#FFF;
			font-size:18px;
			letter-spacing:1.4px;
			line-height:60px;
			font-weight:800;
		}

		a {
			text-decoration:underline;
		}

	}
	@media screen and (max-width:767px) {
		.promo-bar-text-wrap {
			width:calc(100% - 80px);
		}
	}
}
