.menu-wrapper  {
	position:absolute;

	#masthead {
		.row-menu {
			border-bottom:1px solid rgba(255, 255, 255, 0.25);

			#menu-main-header-menu {
				display:inline-flex;
				justify-content:center;
				text-align:center;
				min-width:calc(100% - 268px);
				max-width:calc(100% - 268px);

				> li {
				
					&.menu-right {
						display:none;
					}

					&.current-menu-item {
						a {
							@include navItemUnderline();
						}

						&:last-of-type {
							a {
								&:after {
									right:0;
									width:calc(100% - 20px);
									left:auto;

									@media screen and (max-width: 1159px) {
										width:calc(100% - 12px);
									}
								}
							}
						}
					}

					a {
						color:#FFFFFF;
						font-family:$serenity;
						font-weight:600;
						letter-spacing:1px;
						text-transform:uppercase;
						font-size:15px;
						position:relative;
						padding-left:20px;
						padding-right:20px;
						text-decoration:none;

						&:hover {
							@include navItemUnderline();
						}
					}

					&:first-child {
						a {
							&:hover {
								&:after {
									left:0;
									width:calc(100% - 20px);

									@media screen and (max-width: 1159px) {
										width:calc(100% - 12px);
									}
								}
							}
						}
					}

					&:last-of-type {
						a {
							&:hover {
								&:after {
									right:0;
									left:auto;
									width:calc(100% - 20px);

									@media screen and (max-width: 1159px) {
										width:calc(100% - 12px);
									}
								}
							}
						}
					}
				}
			}

			#header-right {
				display:inline-flex;
				min-width:268px;
				max-width:268px;
				padding:0;
				justify-content: flex-end;

				li {
					a {
						color:$orange !important;
						font-family:$serenity;
						font-weight:600;
						letter-spacing:1px;
						text-transform:uppercase;
						font-size:14px !important;
					}

					+ li {
						margin-left: 30px;
					}

					&.gradient-underline {
						a {
							@include orangeUnderlineBtn();
							padding-right:0;
						}
					}

					&.orange-btn {
						position:relative;
						
						a {
							@include orangeBtn();
							display:inline-block;
							padding:0 30px !important;
							text-align:center;
						}
					}
				}
			}
		}

		// Sticky Menu CSS - is_stuck class applied to #masthead
		&.is_stuck {
			.menu-container  {
				background-color:#FFF;

				.row-menu {
					#main-logo {
						.logo-image {
							height:50px !important;

							&.logo-light {
								display:none;
							}
							&.logo-dark {
								display:block !important;
							}

							img {
								max-height:100%;
								width:auto;
							}
						}
					}
					#menu-main-header-menu {
						>li {
							a {
								color:#000;

								&:hover:after {
									background-color:#bbbbbb;
									background-size:cover;
								}
							}
						}
					}
				}
			}	
		}
		// End Sticky Menu CSS

		@media screen and (max-width:1159px) {
			#logo-container-mobile {
				padding-right:10px;

				img {
					max-width:80px;
					height:auto;
				}
			}

			.row-menu {
				#menu-main-header-menu {
					>li  {
						a  {
							padding-left:12px;
							padding-right:12px;
						}
					}
				}
			}			
		}

		@media screen and (max-width:959px) {
			.menu-container {
				&.menu-open {
					background-color:#FFF !important;
					box-shadow: 0 4px 10px 0 rgba(0,0,0,0.2);

					#main-logo {
						.logo-light {
							display:none !important;
						}

						.logo-dark {
							display:block !important;
						}
					}

					.main-menu-container {
						border-top:rgba(0, 0, 0, 0.15) 1px solid;
					}
				}

				.row-menu-inner {
					border-bottom:#FFF !important;

					#logo-container-mobile {
						padding:10px 10px !important;

						#main-logo {
							.logo-image {
								max-height:50px;
								height:50px;

								img {
									max-width:50px;
								}
							}
						}

						.mobile-menu-button-light {
							.lines,
							.lines:before,
							.lines:after {
								background:$orange url('../../../images/buttons/gradient-underline.png')no-repeat center center;
								background-size:100% 100%;
							}
						}
					}

					#menu-main-header-menu {
						display:block;
						min-width:100%;
						max-width:100%;

						>li  {
							width:100%;
							display:block;

							a {
								color:$blue;
								font-size:18px !important;
								font-weight:600;

								i {
									display:none;
								}
							}
						}
					}

					#header-right {
						display:block;
						min-width:100%;
						max-width:100%;
						margin-top:0 !important;
						padding-bottom:42px;

						li {
							text-align:center;
							margin-left:0;
							margin-right:0;

							+ li {
								margin-top:10px;
							}

							a {
								i {
									display:none;
								}
							}

							&.gradient-underline {
								a {
									background:none !important;
								}
							}
						}
					}
				}
			}
		}
	}
}