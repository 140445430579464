.after-parallax-content.row-container.vc_row {

	&.before-prefooter {
		.row-parent {
			@media screen and (max-width:959px) {
				padding-bottom:0;
			}
		}
	}

	.row-parent {
		padding-top:0;
		padding-bottom: 80px;

		.heading-text {
			padding-top:24px;
			
			h2 {
				@include generalHeader();
			}
		}

		.uncode_text_column {
			max-width:62%;
			margin: 20px auto 0;
		}

		.btn-container {
			margin-top:10px;
		}
	}

	@media screen and (max-width:959px) {
		.row-parent {
			.uncode_text_column {
				max-width:70%;
			}
		}
	}

	@media screen and (max-width:767px) {
		.row-parent {
			padding-bottom:26px;

			.heading-text {
				padding-top:14px;
			}

			.uncode_text_column {
				max-width:100%;
			}

			.btn-container {
				margin-top:0;
			}
		}
	}
}