$sidebar__menu-item__color: #FFFFFF;
$sidebar__bg: $blue;

//
//  map - reset
//
.map-row  {
  margin-bottom: -1px !important;

  .uncont {
    padding: 0 !important;
  }

  .wpgmp_map_container {
    border: 0 none !important;
    box-shadow: initial;
  }

  input[data-input="map-search-control"] {
    max-width:40px;
    right:20px !important;
    background: #FFF url('../../../images/icons/search-icon.png')no-repeat center center;

    &.open-search {
      max-width:none;
      background-position:calc(100% - 20px) center;
    }
  }

  .wpgmp_map_parent {
    margin-bottom:0;
  }
}

//
//  map - sidebar items
//
.map-row  {

  .wpgmp_tab_item {
    text-indent: 0 !important;
    padding: 0 !important;
    border-bottom: 0 none !important;
    position: relative;
    background-color: transparent !important;

    > a {
      text-transform: uppercase;
      font-family:$serenity;
      font-weight:600;
      font-size: 16px;
      letter-spacing:1px;

      &.hidden-icons {
       color:rgba(255, 255, 255, 0.5) !important;
      }
    }

    + .wpgmp_tab_item {
      margin-top:20px;
    }
  }

  .wpgmp_cat_title {
    color: mix(white, $sidebar__menu-item__color, 75%) !important;
  }

  .wpgmp_tab_item:nth-child(1) input[type='checkbox']:checked + .wpgmp_cat_title {
    color: $sidebar__menu-item__color !important;
  }

  .wpgmp_tab_item:nth-child(2) input[type='checkbox']:checked + .wpgmp_cat_title {
    color: $sidebar__menu-item__color !important;
  }

  .wpgmp_tab_item:nth-child(3) input[type='checkbox']:checked + .wpgmp_cat_title {
    color: $sidebar__menu-item__color !important;
  }

  .wpgmp_tab_item:nth-child(4) input[type='checkbox']:checked + .wpgmp_cat_title {
    color: $sidebar__menu-item__color !important;
  }

  .wpgmp_tab_item:nth-child(5) input[type='checkbox']:checked + .wpgmp_cat_title {
    color: $sidebar__menu-item__color !important;
  }

  .wpgmp_tab_item:nth-child(6) input[type='checkbox']:checked + .wpgmp_cat_title {
    color: $sidebar__menu-item__color !important;
  }

  //
  //hides the ugly

  .wpgmp_tab_item input[type='checkbox'] + .wpgmp_cat_title .arrow {
    opacity: 0.5;
    transition: opacity 0.5s;
  }

  .wpgmp_tab_item input[type='checkbox']:checked + .wpgmp_cat_title .arrow {
    opacity: 1;
  }

  .wpgmp_toggle_main_container {
    overflow: visible !important;
    max-height: initial !important;
    padding:0 15px 15px 25px;
  }

  //
  //  map container
  //
  .wpgmp_tabs_container {
    //background-image: url("../../../wp-content/uploads/2017/01/Tellus_Texture_SwirlGrain_K-light-grey.svg") !important;
    background-size: cover !important;
    background-position: left top !important;
    box-shadow: none !important;
    background-color: $sidebar__bg !important;
  }

  .wpgmp_toggle_main_container {
    background-color: $sidebar__bg;
  }

  //
  //  map - sidebar items
  //
  .wpgmp_tabs_container {

    .wpgmp_tab_item .checkbox-wrap {
      display: none;
      position: absolute;
      top: 11px;
      left: 0;
      height: 20px;
      width:20px;
      cursor: pointer;
      background-color: #FFF !important;
      box-shadow: none;
      opacity: 1;
      margin-left:0 !important;
      border-radius:100%;
      border:1px solid #FFF;

      &.hidden-icons {
          background-color:transparent !important;
      }
     input[type='checkbox'] {
      opacity:0 !important;
      height: 20px;
      width:20px;
    }
  }

  .wpgmp_location_container {
    input[type='checkbox'] {
      display:none !important;
      
    }
  }
} 


  .wpgmp_tabs_container .wpgmp_tab_item:hover input[type='checkbox']{
    width:20px !important;
  }

  .wpgmp_tab_item input[type='checkbox']:hover .wpgmp_cat_title {
    transition: translateX();
  }

  .wpgmp_tab_item input[type='checkbox']:checked:before {
    display: none;
  }

  .wpgmp_tab_item input[type='checkbox'] + .wpgmp_cat_title:after {
    content: "";
    display: none;
    height: 2px;
    width: 100%;
    bottom: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    transition: background-color 0.25s;
    background-color: transparent;
  }

  .wpgmp_tab_item input[type='checkbox']:checked + .wpgmp_cat_title:after {
    background-color: currentColor;
  }

  .wpgmp_tab_item .wpgmp_cat_title {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 30px !important;
    text-transform:uppercase !important;
  }

  .wpgmp_tab_item div.wpgmp_toggle_main_container div.wpgmp_tab_item ul.wpgmp_location_container li {
    padding-left: 60px !important;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .wpgmp_tab_item .wpgmp_cat_title span.arrow {
    float: left !important;
    margin-right: 0 !important;
    margin-left: -50px !important;
    width: 36px !important;
    margin-bottom: 10px;
  }

  //
  //  map - handle sidebar positioning
  //
  .wpgmp_tabs_container {
    position: absolute;
    top: 0;
    margin-top: 0 !important;
    left: 0;
    height: 100%;
    width: 300px;
    padding: 60px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transition: transform 0.5s;
  }

  .wpgmp_tabs {
    display: none !important;
  }
}

//
//  map - animation
//
@media (max-width: 959px) {
  .map-row  .wpgmp_tabs_container {
    transform: translateX(-102%);
  }

  .map-sidebar-is-visible {
    .map-row  .wpgmp_tabs_container {
      transform: translateX(0);
    }
  }
}

.map-row  {
  .wpgmp_toggle_container {
   display:none;
  }

  .wpgmp_map {
    height:763px !important;
  }

  @media (min-width: 960px) {
    .map-collapse-toggle {
      opacity: 0;
      pointer-events: none;
    }

    .wpgmp_map > div:first-child {
      width: calc(100% - 300px) !important;
      transform: translateX(300px);
    }
  }

  //
  //  map - collapse toggle
  //
  .map-collapse-toggle {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 100;
    border-radius: 2px;
    font-size: 11px;
    font-family: "serenity", sans-serif !important;
    font-weight: 700;
    padding: 10px;
    line-height: 1;
    background-color: white;
    box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.29804);
  }
}

//
//  infomessage link
//  ~added via manage maps in plugin settings
.tellus-map__link {
  //color: $green--saturated !important;
  transition: opacity 0.25s;
  opacity: 1;

  &:hover {
    opacity: 0.75;
  }
}

//
//  map sidebar nested element
div.wpgmp_toggle_main_container .jspContainer {
   background-color:$blue;
}

//
//  map sidebar nested links
div.wpgmp_toggle_main_container div.wpgmp_tab_item {
  ul.wpgmp_location_container {
    background-color:$blue;
    & {
      padding-left: 30px;
    }

    li {

      position:relative;
      padding-left:0 !important;

      input[type="checkbox"] {
        display:none !important;
      }

      a {
        color: white !important;
        font-family:$serenity;
        font-weight:300;
        font-size:14px;
        line-height:38px;
        letter-spacing:1px;
        text-indent:5px;
        padding-left:0 !important;

        &:before {
          content:'';
          width:10px;
          height:1px;
          background-color:#FFF;
          display:inline-block;
          margin-right:4px;
          vertical-align:middle;
          position:relative;
          top:-2px;
        }
      }
    }
  }
}

//
//  address bug with subcategories in sidebar
.wpgmp_location_container {
  > li {
    position: relative;
  }

  > li > input[type='checkbox'] {
    height: 0 !important;
    width: 0 !important;
    left: 0 !important;
  }

  > li > input[type='checkbox'] + a {
    transition: opacity 0.5s;
  }

  > li > input[type='checkbox']:checked + a {
    opacity: 1;
  }
}
//  //  strange hover effect
//  .wpgmp_map_container .wpgmp_tabs_container .wpgmp_tab_item .wpgmp_cat_title {
//    color: mix(white, $sidebar__menu-item__color, 75%) !important;
//  }
//  .wpgmp_map_container .wpgmp_tabs_container .wpgmp_tab_item .wpgmp_cat_title:hover {
//    color: $sidebar__menu-item__color !important;
//  }





//
//  neighborhood - map
//
.fc-main {}

.fc-badge {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 10px !important;
  font-weight: 700;
  border-radius: 2px !important;
  padding: 4px 6px !important;
  line-height: 1;
  margin-bottom: .5rem;
}

.wpgmp_iw_content {

  .fc-item-title {
    margin-bottom: 0 !important;
    font-family:$serenity;
    font-weight: 800;
    font-size:16px;
    line-height:19px;
    text-transform:uppercase; 
  }
  address {
    font-family:$serenity;
    font-weight:400;
    font-size:14px;
    font-style:normal;
    margin-top:0;
  }
}

.fc-item-address {
  font-style: normal;
  color: black;
  margin-top: 0;
}

@media screen and (max-width:959px) {
  .map-row {
    .row {
      padding-bottom:0 !important;
      padding-top:0 !important;
    }
  }
}