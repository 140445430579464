.orange-divider {
	background:url('../../../images/orange-gradient-bar.png')no-repeat center center;
	background-size:cover;
	height:35px;
	max-height:35px;
	overflow:hidden;
	padding:0;
	box-sizing:border-box;

	@media screen and (max-width:767px) {
		height:20px;
		max-height:20px;
	}
}