.gallery-section.row-container {
	margin-bottom:50px;

	.heading-text {
		text-align:center;
		h2 {
			color: #1A3A58;
			font-size:26px;
			font-weight:800;
			text-transform:uppercase;
			letter-spacing:4.3px;
			font-family: $serenity;
		}
	}

	.isotope-system + .isotope-system {
		margin-top:0 !important;
	}

	.isotope-container {
		margin-left:-15px;
		margin-right:-15px;

		.tmb {
			padding:15px;
		}
	}

	@media screen and (max-width:958px) {

		margin-bottom:0;

		.row {
			
			.isotope-container {
				.tmb {
					width:50% !important;
				}
			}
		}
	}

	@media screen and (max-width:767px) {

		.row {
			padding-top:12px;
			padding-bottom:12px;

			.isotope-container {
				margin-left:0;
				margin-right:0;

				.tmb {
					padding:0;
					width:100% !important;
					margin-bottom:25px;
				}
			}
		}
	}
}