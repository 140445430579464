body.error404 {
	@include alternateHeader();

	.page-body {
		.post-body {
			padding-top:200px;

			@media screen and (max-width:959px) {
				padding-top:100px;
			}

			@media screen and (max-width:959px) {
				padding-top:80px;
			}


			.content-404 {
				.heading-text {
					text-align:center;

					h1 {
						font-size: 36px;
					    color: $blue !important;
					    font-family: serenity,sans-serif!important;
					    text-transform: uppercase;
					    letter-spacing: 6px;
						span {
							font-family: ltc-bodoni-175,serif!important;
						    font-size: 120px;
						    line-height: 130px;
						    letter-spacing: 3.4px;
						    display: block;
						    font-weight: 400;
						    margin-top:10px;
						}
					}
				}

				.uncode_text_column {
					max-width:45%;
					margin-left:auto !important;
					margin-right:auto !important;
					margin-top:0 !important;
					text-align:center;
				}

				@media screen and (max-width:959px) {

					.heading-text {
						h1 {
							font-size: 30px;
	    					letter-spacing: 5px;

							span {
								font-size: 80px;
							    line-height: 100px;
							    letter-spacing: 2.5px;
							}
						}
					}

					.uncode_text_column {
						max-width:600px;
					}
				}

				@media screen and (max-width:767px) {

					.heading-text {
						h1 {
							font-size: 24px;
						    letter-spacing: 4px;
						    line-height: 26px;

							span {
								font-size: 50px;
							    line-height: 60px;
							    letter-spacing: 1.42px;
							}
						}
					}

					.uncode_text_column {
						max-width:100%;
					}
				}
			}

		}
	}

	.pre-footer-row {
		padding-top:190px;
		margin-top:40px !important;

		@media screen and (max-width:959px) {
			padding-top:55px;
		}

		@media screen and (max-width:767px) {
			padding-top:20px;
		}
	}
}