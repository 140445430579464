.page-template-page-amenities {

	.parallax-hero {
		padding-bottom:260px;
		padding-top:160px;

		&:after {
			display:none !important;
		}

		.row-parent .row-inner .column_parent .heading-text{

			h1 {
				color:#FFFFFF !important;

				span {
					color:#FFFFFF !important;
				}
			}

			&.parallax-subheader {
				h2 span {
					@include parallaxSubheader();
				}
			}
		}

		.uncode_text_column {
			max-width:75%;
			margin:0 auto;
			color:#FFFFFF;
		}

		
	}

	// Sections
	.interior-design-option {
		.interior-type-intro-text { 
			background-image: url('../../../images/backgrounds/grey-white-angle-bg.jpg');
			background-repeat:no-repeat;
			background-position: calc(50% + 36px) center;
			background-size:cover;

			.uncode_text_column {
				max-width:47%;

				@media screen and (max-width:959px) {
					max-width:80%;
				}

				@media screen and (max-width:767px) {
					max-width:100%;
				}
			}
		}

		&.additional-amenities {
			.uncode_text_column {
				span {
					color:$orange;
				}
			}
		}

	}

	// End Sections
	.pre-footer-row  {
		margin-top:160px;

		.uncont .prefooter-cta .heading-text {
			max-width:75%;
		}
	}

	@media screen and (max-width:767px) {
		.parallax-hero {
			padding-top: 80px;
			padding-bottom: 60px;
		}
	}
}